
import { RevSkeleton } from '@ds/components/Skeleton'

import translations from './ProductsSkeleton.translations'

export default {
  components: {
    RevSkeleton,
  },

  computed: {
    translations: () => translations,
  },
}
