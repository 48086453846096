
import { RevButton } from '@ds/components/Button'
import { RevCardCarousel } from '@ds/components/CardCarousel'
import isEmpty from 'lodash/isEmpty'

import ProductThumb from '@landing/components/ProductThumb'
import {
  PRODUCT_DEAL_TYPE,
  PRODUCT_DEFAULT_SHIPPING_COST,
  PRODUCT_PROVIDERS,
  PRODUCT_SOURCE,
} from '@tracking'

import {
  findResultsFromQuery,
  getProductsWithWarranty,
} from '../../helpers/algolia'
import algoliaTranslations from '../../helpers/algolia/algolia.translations'
import { sourceTypeForProductsTracking } from '../../helpers/tracking'
import translations from '../../helpers/translations/cardSlider.translations'
import BlockTitle from '../private/BlockTitle'

import ProductsSkeleton from './ProductsSkeleton.vue'

export default {
  components: {
    RevButton,
    RevCardCarousel,
    BlockTitle,
    ProductThumb,
    ProductsSkeleton,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    blockPosition: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    productQueries: {
      type: Array,
      required: true,
    },
    ctaLink: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      products: [],
      isLoading: false,
    }
  },
  async fetch() {
    this.isLoading = true

    const { getWarrantyByProduct } = this
    const { ALGOLIA_ID } = this.$config

    const algoliaResultPromises = this.productQueries.map(
      ({ apiKey, ...query }) => {
        return findResultsFromQuery({
          ALGOLIA_ID,
          ALGOLIA_KEY: apiKey,
          query,
        })
      },
    )

    const algoliaResults = await Promise.all(algoliaResultPromises)

    const allProductsWithWarranty = algoliaResults.flatMap((algoliaResult) => {
      return getProductsWithWarranty({
        algoliaResult,
        getWarrantyByProduct,
      })
    })

    this.products.push(...allProductsWithWarranty)

    this.isLoading = false
  },
  computed: {
    translations: () => translations,
    hasProducts() {
      return !isEmpty(this.products)
    },

    isVisible() {
      return this.hasProducts && !this.isLoading
    },

    hasCtaLink() {
      return !isEmpty(this.ctaLink)
    },

    sourceType() {
      const { name, params } = this.$route

      return sourceTypeForProductsTracking({
        blockPosition: this.blockPosition,
        pageName: params.pageName,
        routeName: name,
      })
    },
  },
  methods: {
    getWarrantyByProduct(product) {
      return this.$t(algoliaTranslations.warranty, {
        duration: product.warranty,
      })
    },

    sendTrackingProductClickData(product, index) {
      const trackingData = {
        widgetId: null,
        list: this.sourceType,
        product: {
          brand: product.brand,
          category: product.category_3,
          name: product.title,
          price: product.price,
          currency: product.currency,
          id: product.backmarketID,
          // TODO: URL-352 Use product.id when available in Algolia to get the UUID
          uuid: (product.link_grade_v2 || product.link).params.uuid,
          variant: product.backbox_grade_value,
          list: this.sourceType,
          position: index + 1,
          color: product.color,
          merchantId: product.merchant_id,
          model: product.title_model,
          dealType: PRODUCT_DEAL_TYPE.NORMAL,
          source: PRODUCT_SOURCE.SEARCH,
          listingId: product.objectID.toString(),
          shippingPrice: PRODUCT_DEFAULT_SHIPPING_COST,
          provider: PRODUCT_PROVIDERS.ALGOLIA,
        },
      }

      this.$emit('track-product-click', trackingData)
    },
  },
}
